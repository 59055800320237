import React, { useState, useEffect, useMemo } from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useDispatch, useSelector } from 'react-redux'

import { addSpecialDeals } from '../Redux/SpecialDeals/SpecialDealsActions';
import { Link, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment'; 
import Switch from "react-switch";
import Loader from 'react-loader-spinner';
import {
  FormControl
} from "react-bootstrap";
import TimePicker from 'rc-time-picker';


function SpecialDeals() {
 

  const history = useHistory();
  const goToBack = () => {
      history.goBack();
  }

  const format = 'HH:mm';

  const now = moment().hour(0).minute(0);

  const [forms, setForms] = useState([{ startDate: new Date(), startTime: "00:00", endDate: new Date(), endTime: "00:00" }]);
  const [type, setType] = useState('DAILY');
  const [saleStatus, setSaleStatus] = useState(false);
  const [isAdded, setAdded] = useState(false);
  const [sdate, setSdate] = useState(new Date());
  const [index, setIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoaded, setLoaded] = useState(true);

  const specialDealsData = useSelector(state => state.SpecialDeals);

  const addForm = () => {
    setAdded(false);
    var startdt = new Date();
    var enddt = new Date();
    if(type == 'TOMORROW'){
      startdt.setDate(startdt.getDate()+1);
      enddt.setDate(enddt.getDate()+1);
    } else if(type == 'THIS_WEEK'){
      enddt.setDate(enddt.getDate()+6);
    }
    setForms([...forms, { startDate: startdt, startTime: "00:00", endDate: enddt, endTime: "00:00" }]);
  };
  
  const removeForm = () => {
    if (forms.length > 1) {
      setAdded(false);
      setForms(forms.slice(0, -1));
    }
  };  

  useMemo(() =>{
    if(specialDealsData.get_error) {
      setLoaded(true);
      setErrorMessage(specialDealsData.get_error.object.error)
    }

    if(specialDealsData && specialDealsData.add_special_deals && specialDealsData.add_special_deals.request_status) {
     setLoaded(true);
      setResponseMessage('added Successfully');
      setErrorMessage(null);
    }
  },[specialDealsData])

  const enableDisableStatus = (status) => {
    setSaleStatus(status);
    setInputValues({ ...inputValues, available_online: status });
  }

  const handleDateChange = (index, value) => {
    const updatedForms = forms.map((form, i) => 
      i === index ? { ...form, startDate: value } : form
    );
    setForms(updatedForms); 
  }

  const handleType = (value) => {
    setForms([forms[0]])
    var startdt = new Date();
    var enddt = new Date();
    if(value == 'TOMORROW'){
        startdt.setDate(startdt.getDate()+1);
        enddt.setDate(enddt.getDate()+1);
    } else if(value == 'THIS_WEEK'){
        enddt.setDate(enddt.getDate()+6);
    }
    setForms([{ startDate: startdt, startTime: forms[0].startTime, endDate: enddt, endTime: forms[0].endTime }]);
    setType(value)
  }
  
  const handleEndDateChange = (index, value) => {
    const updatedForms = forms.map((form, i) => 
      i === index ? { ...form, endDate: value } : form
    );
    setForms(updatedForms);
  }

  const handleTimeChange = (index, value) =>{
    
      const selectedTime = value && value.format('HH:mm') ? value.format('HH:mm') : "00:00";
      const updatedForms = forms.map((form, i) => 
        i === index ? { ...form, startTime: selectedTime } : form
      );
      setForms(updatedForms);
   }

   const convertDateTimeArray = (dateTimeArray) => {
    const formattedObject = {};
    dateTimeArray.forEach(({ startDate, startTime, endDate, endTime }) => {
      const startDateFormatted = new Date(startDate).toLocaleDateString('en-GB');
      const endDateFormatted = new Date(endDate).toLocaleDateString('en-GB');
      const key = `${startDateFormatted} ${startTime} - ${endDateFormatted} ${endTime}`;
  
      formattedObject[key] = true;
    });
  
    return formattedObject;
  };
  

   const handleClosingTimeChange = (index, value) =>{

      const selectedTime = value && value.format('HH:mm') ? value.format('HH:mm') : "00:00";
      const updatedForms = forms.map((form, i) => 
        i === index ? { ...form, endTime: selectedTime } : form
      );
      setForms(updatedForms);
      
   }

  // add category
  const [inputValues,setInputValues] = useState({
    name:"",
    description:"",    
    type:"SPECIAL_DEALS",
    available_online:false,
    configurations:{}
  })

  const [inputValuesdefault,setInputValuesdefault] = useState({
    name:"",
    description:"",    
    type:"SPECIAL_DEALS",
    available_online:false,
    configurations:{}
  })
 
  const [msg,setMsg] = useState(false);
  const dispatch = useDispatch()

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedForms = forms.map((form, i) => 
      i === index ? { ...form, [name]: value } : form
    );
    setForms(updatedForms); 
  };

  const specialDealsHandler = (event) =>{
    event.preventDefault()
    setAdded(true);
    setErrorMessage(null);
    setResponseMessage(null);
    const configuration = convertDateTimeArray(forms)
    // console.log('forms==='+JSON.stringify(configuration))
    if(inputValues.name.trim() == '') {
      setErrorMessage('name is required!');
    } else if(inputValues.description.trim() == '') {
      setErrorMessage('description is required!');
    } else {    
      setInputValues((inputValues) =>({ ...inputValues, configurations: configuration }));  
    }
  }

  useEffect(() => {
    if (inputValues.configurations && isAdded) {
      setLoaded(false)
      console.log("Updated inputValues:", JSON.stringify(inputValues));
      dispatch(addSpecialDeals(inputValues));
    }
  }, [inputValues, dispatch]);
  
  return (
    <>
      {/* Breadcubs Area Start Here */}
      <div className="breadcrumbs-area">
        <button className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark right-float" onClick={(e)=>goToBack()}>Back</button>
        <h3>Special Deals</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li onClick={(e)=>goToBack()}>
            Special Deals
          </li>
        </ul>
      </div>

 

      <div className="row">
        <div className="col-12-xxxl col-12">
          <div className="card">

            <div className="card-body">

              <div className="heading-layout1">
                <div className="item-title">
                  <h3>Add Special Deals</h3>
                </div>

              </div>
              {isLoaded == false && <div style={{textAlign: "center"}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={90}
                    width={150}
                  />
                </div> }
              <div className="row">
              
              {responseMessage ?
                <p className="alert alert-success w-auto" role="alert">
                  {responseMessage}
                </p> : <p></p>
              }

              {errorMessage ?
                <p className="alert alert-danger w-auto" role="alert">
                 {errorMessage}
                </p> : <p></p>
              }

          </div>
              <form onSubmit={(e) =>specialDealsHandler(e)}>
               
                <div className="row">
                  <div className="col-lg-6 col-12 form-group">
                    <label>Name</label>
                    <input type="text" placeholder className="form-control" value={inputValues.name} onChange={(e) =>setInputValues({...inputValues,name:e.target.value})} required />
                  </div>
                  
                  <div className="col-lg-6 col-12 form-group">
                    <label>Description</label>
                    <textarea
                      placeholder="Enter description"
                      className="form-contrl"
                      value={inputValues.description}
                      onChange={(e) => setInputValues({ ...inputValues, description: e.target.value })}
                      required
                     />
                    {/* <input type="text" placeholder className="form-control" value={inputValues.description} onChange={(e) =>setInputValues({...inputValues,description:e.target.value})} required /> */}
                  </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-12 form-group">
                    <label style={{marginLeft:'30px'}}>Online Sale</label>
                     <div style={{marginTop:'-30px', marginLeft:'130px'}}><Switch
                        onChange={(e) =>
                          enableDisableStatus(!saleStatus)
                        }
                        checked={saleStatus}
                        uncheckedIcon={false}
                        checkedIcon={true}                        
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/>
                        </div>
                     </div>

                     <div className="col-lg-6 col-12 form-group">
                     <label>Display On</label>
                          <FormControl
                            required
                            type="text"
                            onChange={(e) =>handleType(e.target.value)}
                            as="select">
                            <option value="DAILY">Today</option>
                            <option value="TOMORROW">Tomorrow</option>
                            <option value="THIS_WEEK">This Weekend</option>
                            <option value="CUSTOM">Custom</option>
                          </FormControl>
                     </div>
                  </div>

                  
                  {/* <div className="col-lg-6 col-12 form-group">
                    <label>Order Index</label>
                    <input type="number" placeholder className="form-control" value={inputValues.order} onChange={(e) =>setInputValues({...inputValues,order:e.target.value})} required />
                  </div>
                  <div className="col-lg-6 col-12 form-group">
                  <FormGroup>
                      <FormLabel>Select Status</FormLabel>
                      <FormControl
                        required
                        type="text"
                        value={inputValues.catStatus} onChange={(e) =>setInputValues({...inputValues,catStatus:e.target.value})}
                      
                        as="select">
                       
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="INACTIVE">INACTIVE</option>

                      </FormControl>
                    </FormGroup>
                   
                     </div>
                */}

              <div className="right-float mg-t-20 mg-l-10">
                  <button type="button" className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark mg-b-10" onClick={addForm}>+</button>
                  <br/>
                  <button type="button" className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark" onClick={removeForm}>-</button>
               </div>
               <div className="mg-t-20">
               <div className="row card-body mg-t-10">
               {type=="CUSTOM" && <div className="col-lg-4 col-12 form-group">
                  <label>Start Date</label>
               </div>}

               <div className="col-lg-2 col-12 form-group">
                  <label>Start Time</label>
               </div>

               {type=="CUSTOM" && <div className="col-lg-4 col-12 form-group">
                  <label>End Date</label>
               </div>}

               <div className="col-lg-2 col-12 form-group">
                  <label>End Time</label>
               </div>

           </div>


                {/* <div className='row'> */}
                {forms.map((form, index) => (
            <form key={index} className="dynamic-form">

           <div className="row card-body mg-t-10 mg-r-60 mg-l-4">
               
              {type=="CUSTOM" && <div className="col-lg-4 col-12 form-group">
                   <DatePicker selected={form.startDate} className="form-control" onChange={(date) => handleDateChange(index, date)} />
               </div>}
               <div className="col-lg-2 col-12 form-group">
                   <TimePicker
                                 showSecond={false}
                                 defaultValue={now}
                                 className="form-control timeselector"
                                 onChange={(time) => handleTimeChange(index, time)}
                                 format={format}
                                 use12Hours
                                 inputReadOnly
                               />
               </div>
               {type == "CUSTOM" && <div className="col-lg-4 col-12 form-group">
                   <DatePicker selected={form.endDate} className="form-control" onChange={(date) => handleEndDateChange(index, date)} />
               </div>}

               <div className="col-lg-2 col-12 form-group">
                   <TimePicker
                                 showSecond={false}
                                 defaultValue={now}
                                 className="form-control timeselector"
                                 onChange={(time) => handleClosingTimeChange(index, time)}
                                 format={format}
                                 use12Hours
                                 inputReadOnly
                               />
               </div>
   

           </div>       
            </form>
          ))}
                {/* </div> */}
                </div>
                  <div className="col-12 form-group mg-t-20">
                    <button type="submit" className="btn-fill-lg bg-blue-dark btn-hover-yellow float-right">Save</button>            
                  </div>
                {/* </div> */}
              </form>
            </div>


          </div>
        </div>
      </div>


   

    </>

  );
}

export default SpecialDeals;
