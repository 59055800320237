import {
  ADD_GIFT_CERTIFICATE_REQUEST,
  ADD_GIFT_CERTIFICATE_SUCCESS,
  ADD_GIFT_CERTIFICATE_FALIURE,
  DELETE_GIFT_CERTIFICATE_REQUEST,
  DELETE_GIFT_CERTIFICATE_SUCCESS,
  DELETE_GIFT_CERTIFICATE_FALIURE,
  GET_GIFT_CERTIFICATE_REQUEST,
  GET_GIFT_CERTIFICATE_SUCCESS,
  GET_GIFT_CERTIFICATE_FALIURE
} from './GiftCertificateConstants'
import {config} from '../config'

export const addGiftCertificateRequest = (gallery_req) =>{
  return{
    type : ADD_GIFT_CERTIFICATE_REQUEST,
    payload : gallery_req
  }
}

export const addGiftCertificateSuccess = (gallery_req_success) =>{
  return{
    type : ADD_GIFT_CERTIFICATE_SUCCESS,
    payload : gallery_req_success
  }
}

export const addGiftCertificateFaliure = (error) =>{
  return{
    type : ADD_GIFT_CERTIFICATE_FALIURE,
    payload : error
  }
}

export const getGiftCertificateRequest = (gallery_req) =>{
  return{
    type : GET_GIFT_CERTIFICATE_REQUEST,
    payload : gallery_req
  }
}

export const getGiftCertificateSuccess = (gallery_req_success) =>{
  return{
    type : GET_GIFT_CERTIFICATE_SUCCESS,
    payload : gallery_req_success
  }
}

export const getGiftCertificateFaliure = (error) =>{
  return{
    type : GET_GIFT_CERTIFICATE_FALIURE,
    payload : error
  }
}

export const deleteGiftCertificateRequest = (gallery_req) =>{
  return{
    type : DELETE_GIFT_CERTIFICATE_REQUEST,
    payload : gallery_req
  }
}

export const deleteGiftCertificateSuccess = (gallery_req_success) =>{
  return{
    type : DELETE_GIFT_CERTIFICATE_SUCCESS,
    payload : gallery_req_success
  }
}

export const deleteGiftCertificateFaliure = (error) =>{
  return{
    type : DELETE_GIFT_CERTIFICATE_FALIURE,
    payload : error
  }
}

export const addGiftCertificate = (type, giftFields) =>{
  return(dispatch) => {
    dispatch(addGiftCertificateRequest(false))
    const url =`${config.api_root}/api/v1/merchants/gift_certificate?type=${type}`
    const request_option = {
       method: "POST",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          fields:giftFields
      })
    }
    fetch(url, request_option)
     .then(response => response.json() )
     .then(res =>{
        if(res.request_status == true){
          dispatch(addGiftCertificateSuccess(res))
        } else {
          dispatch(addGiftCertificateFaliure(res))
        }
     })
    .catch(error => {
      const errorMsg = error
      dispatch(addGiftCertificateFaliure(errorMsg))
    })
  }
}


export const deleteGiftCertificate = (type) =>{
  return(dispatch) => {
    dispatch(deleteGiftCertificateRequest(false))
    const url =`${config.api_root}/api/v1/merchants/gift_certificate?type=${type}`
    const request_option = {
       method: "DELETE",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        }
    }
    fetch(url, request_option)
     .then(response => response.json() )
     .then(res =>{
        if(res.request_status == true){
          dispatch(deleteGiftCertificateSuccess(res))
        } else {
          dispatch(deleteGiftCertificateFaliure(res))
        }
     })
    .catch(error => {
      const errorMsg = error
      dispatch(deleteGiftCertificateFaliure(errorMsg))
    })
  }
}

export const getGiftCertificate = () =>{
  return(dispatch) => {
    dispatch(getGiftCertificateRequest(false))
    const url =`${config.api_root}/api/v1/merchants/gift_certificate`
    const request_option = {
       method: "GET",
       headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ config.token,
            'Content-Type': 'application/json'
        }
    }
    fetch(url, request_option)
     .then(response => response.json() )
     .then(res =>{
      //console.log(`request_status=${res.request_status}`)
      if(res.request_status == true){
        dispatch(getGiftCertificateSuccess(res))
      } else {
        dispatch(getGiftCertificateFaliure(res))
      }
     })
    .catch(error => {
      const errorMsg = error
      dispatch(getGiftCertificateFaliure(errorMsg))
    })
  }
}
