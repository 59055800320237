import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FALIURE,
  DEVICE_REQUEST,
  DEVICE_SUCCESS,
  DEVICE_FALIURE
} from './LoginConstants'
import {config} from '../config'
import {v4 as uuidv4} from 'uuid';

export const loginRequest = (login_info) =>{
  return{
    type : LOGIN_REQUEST,
    payload : login_info
  }
}

export const loginSuccess = (login_success) =>{
  return{
    type : LOGIN_SUCCESS,
    payload : login_success
  }
}

export const loginFaliure = (error) =>{
  return{
    type : LOGIN_FALIURE,
    payload : error
  }
}

export const deviceRequest = (device_request) =>{
  return{
    type : DEVICE_REQUEST,
    payload : device_request
  }
}

export const deviceSuccess = (device_success) =>{
  return{
    type : DEVICE_SUCCESS,
    payload : device_success
  }
}

export const deviceFaliure = (error) =>{
  return{
    type : DEVICE_FALIURE,
    payload : error
  }
}

export const login = (login_info) =>{
    return(dispatch) => {
      dispatch(loginRequest(login_info))
      const url =`${config.api_root}/security/session/users/login`;
      const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              key : 'tiffin',
              secret : 'tiffin',
              device_id: 454512454245454,

          },
      body: JSON.stringify({
        user : login_info.email,
        password : login_info.password,
        console:"SELLER"
      })
    }
    fetch(url, request_option)
    .then(response => response.json())
    .then(login_res =>{
      //console.log('login_res', login_res);
      config.mid = login_res.object.mid;
      config.permissionType = login_res.object.permission_type;
      localStorage.setItem("mid", login_res.object.mid);
      localStorage.setItem("ordertime", login_res.object.ORDER_PREPARATION_TIME);
      localStorage.setItem("orderunit", login_res.object.ORDER_PREPARATION_TIME_UNIT);
      localStorage.setItem("permission_type", login_res.object.permission_type);
      console.log(login_res.object.ORDER_PREPARATION_TIME);
      console.log(login_res.object.ORDER_PREPARATION_TIME_UNIT);
      const login_success = login_res
      dispatch(loginSuccess(login_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(loginFaliure(errorMsg))
    })
    }
}

const browserId = () => {
  var ua= navigator.userAgent;
  var tem; 
  var M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if(/trident/i.test(M[1])){
      tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE '+(tem[1] || '');
  }
  if(M[1]=== 'Chrome'){
      tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
      if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
  if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
  return M.join('');
}

export const registerDevice = (device_token) =>{

  let version = '';
  if(browserId()){
    version = browserId()
  }

  return(dispatch) => {
    dispatch(deviceRequest(device_token))
    const url =`${config.api_root}/api/v1/users/register_device`; 
    const mapModal = {'groupName': 'DT_WEB'};
    const request_option = {
      method: "POST",
      headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer '+ config.token,
              'Content-Type': 'application/json'
      },
      body: JSON.stringify({
            form_id: "",
            user_id: config.user_id,
            fields:{
               smartDeviceOS: 'BROWSER',
               deviceToken: device_token,
               deviceId: uuidv4(),
               deviceInfo: version,
               pushNotificationService: mapModal
            },
      })
  }
  fetch(url, request_option)
    .then(response => response.json())
    .then(device_reg_res =>{
      const device_reg_success = device_reg_res
      dispatch(deviceSuccess(device_reg_success))
    })
    .catch(error => {
      const errorMsg = error
      dispatch(deviceFaliure(errorMsg))
  })

  }
}