import React, { Component } from 'react';
import { Link, BrowserRouter as Router } from 'react-router-dom'  
import { connect } from 'react-redux';
import Notification from '../firebaseNotifications/Notification'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { config } from '../Redux/config';




class Sidebar extends Component {

  

  constructor(props){
    super(props);
    this.state = {
      displayDashboard: false,
      displaystudent : false,
      displayparents : false,
      displaydrivers : false,
      displayteachers : false,
      displaylibrary : false,
      displayAccount : false,
      displayclass : false,
      displayhostel : false,
      displaytransport:false,
      displaymedium:false,
      displayexam : false,
      displayuielements : false,
      displaymessage:false,
      displayattendence:false,
      displayprofession:false
    };

 this.showDropdownMenu = this.showDropdownMenu.bind(this);
 this.hideDropdownDashboard = this.hideDropdownDashboard.bind(this);
 this.hideDropdownuielements = this.hideDropdownuielements.bind(this);
 this.hideDropdownmessage = this.hideDropdownmessage.bind(this);
 this.hideDropdownexam = this.hideDropdownexam.bind(this);
 this.hideDropdownAccount = this.hideDropdownAccount.bind(this);
 this.hideDropdownclass = this.hideDropdownclass.bind(this);
 this.hideDropdownhostel = this.hideDropdownhostel.bind(this);
 this.hideDropdowntransport = this.hideDropdowntransport.bind(this);
 this.hideDropdownmedium = this.hideDropdownmedium.bind(this);
 this.hideDropdownprofession = this.hideDropdownprofession.bind(this);
 this.hideDropdownteachers = this.hideDropdownteachers.bind(this);
 this.hideDropdownlibrary = this.hideDropdownlibrary.bind(this);
 this.hideDropdownparents = this.hideDropdownparents.bind(this);
 this.hideDropdowndrivers = this.hideDropdowndrivers.bind(this);
 this.hideDropdownstudent = this.hideDropdownstudent.bind(this);
 this.hideDropdownattendence = this.hideDropdownattendence.bind(this);
  }

  showDropdownMenu(event,value1) {
  
    event.preventDefault();
    if(value1 == 'dashboard'){
      return this.setState({ displayDashboard: true }, () => {
        document.addEventListener('click',this.hideDropdownDashboard);
        });
    }
  else if(value1 == 'student'){
    return this.setState({ displaystudent: true }, () => {
      document.addEventListener('click', this.hideDropdownstudent);
      });
  }
  else if(value1 == 'teachers'){
    return this.setState({ displayteachers: true }, () => {
      document.addEventListener('click', this.hideDropdownteachers);
      });
  }
  else if(value1 == 'parents'){
    return this.setState({ displayparents: true }, () => {
      document.addEventListener('click', this.hideDropdownparents);
      });
  }
  else if(value1 == 'driver'){
    return this.setState({ displaydrivers: true }, () => {
      document.addEventListener('click', this.hideDropdowndrivers);
      });
  }
  else if(value1 == 'library'){
    return this.setState({ displaylibrary: true }, () => {
      document.addEventListener('click', this.hideDropdownlibrary);
      });
  }
  else if(value1 == 'Account'){
    return this.setState({ displayAccount: true }, () => {
      document.addEventListener('click', this.hideDropdownAccount);
      });
  }
  else if(value1 == 'class'){
    return this.setState({ displayclass: true }, () => {
      document.addEventListener('click', this.hideDropdownclass);
      });
  }
  else if(value1 == 'medium'){
    return this.setState({ displaymedium: true }, () => {
      document.addEventListener('click', this.hideDropdownmedium);
      });
  }
  else if(value1 == 'hostel'){
    return this.setState({ displayhostel: true }, () => {
      document.addEventListener('click', this.hideDropdownhostel);
      });
  }
  else if(value1 == 'transport'){
    return this.setState({ displaytransport: true }, () => {
      document.addEventListener('click', this.hideDropdowntransport);
      });
  }
  else if(value1 == 'profession'){
    return this.setState({ displayprofession: true }, () => {
      document.addEventListener('click', this.hideDropdownprofession);
      });
  }
  else if(value1 == 'exam'){
    return this.setState({ displayexam: true }, () => {
      document.addEventListener('click', this.hideDropdownexam);
      });
  }
  else if(value1 == 'attendence'){
    return this.setState({ displayattendence: true }, () => {
      document.addEventListener('click', this.hideDropdownattendence);
      });
  }
  else if(value1 == 'ui-elements'){
    return this.setState({ displayuielements: true }, () => {
      document.addEventListener('click', this.hideDropdownuielements);
      });
  }
  else if(value1 == 'message'){
    return this.setState({ displaymessage: true }, () => {
      document.addEventListener('click', this.hideDropdownmessage);
      });
  }
  }

  hideDropdownuielements() {
    this.setState({ displayuielements: false }, () => {
      document.removeEventListener('click', this.hideDropdownuielements);
    });
  }
  hideDropdownmessage() {
    this.setState({ displaymessage: false }, () => {
      document.removeEventListener('click', this.hideDropdownmessage);
    });
  }

  hideDropdownexam() {
    this.setState({ displayexam: false }, () => {
      document.removeEventListener('click', this.hideDropdownexam);
    });
  }
  hideDropdownclass() {
    this.setState({ displayclass: false }, () => {
      document.removeEventListener('click', this.hideDropdownclass);
    });
  }
  hideDropdownhostel() {
    this.setState({ displayhostel: false }, () => {
      document.removeEventListener('click', this.hideDropdownhostel);
    });
  }
  hideDropdowntransport() {
    this.setState({ displaytransport: false }, () => {
      document.removeEventListener('click', this.hideDropdowntransport);
    });
  }
  hideDropdownmedium() {
    this.setState({ displaymedium: false }, () => {
      document.removeEventListener('click', this.hideDropdownmedium);
    });
  }
  hideDropdownprofession() {
    this.setState({ displayprofession: false }, () => {
      document.removeEventListener('click', this.hideDropdownprofession);
    });
  }
  hideDropdownattendence() {
    this.setState({ displayattendence: false }, () => {
      document.removeEventListener('click', this.hideDropdownattendence);
    });
  }
  hideDropdownAccount() {
    this.setState({ displayAccount: false }, () => {
      document.removeEventListener('click', this.hideDropdownAccount);
    });
  }

  hideDropdownlibrary() {
    this.setState({ displaylibrary: false }, () => {
      document.removeEventListener('click', this.hideDropdownlibrary);
    });
  }
  hideDropdownDashboard() {
    this.setState({ displayDashboard: false }, () => {
      document.removeEventListener('click', this.hideDropdownDashboard);
    });
  }
    hideDropdownstudent() {
      this.setState({ displaystudent: false }, () => {
        document.removeEventListener('click', this.hideDropdownstudent);
      });

  }
  hideDropdownteachers() {
    this.setState({ displayteachers: false }, () => {
      document.removeEventListener('click', this.hideDropdownteachers);
    });
}
hideDropdownparents() {
  this.setState({ displayparents: false }, () => {
    document.removeEventListener('click', this.hideDropdownparents);
  });
}
hideDropdowndrivers() {
  this.setState({ displaydrivers: false }, () => {
    document.removeEventListener('click', this.hideDropdowndrivers);
  });
}
openPageHandler = (event,selected_value) =>{
  event.preventDefault();
  if(selected_value == 'page-admin'){
    window.location='/';
  }
else if(selected_value == 'page-students'){
    window.location='/students';
}
else if(selected_value == 'page-teachers'){
  window.location='/teachers';
}
else if(selected_value == 'page-parents'){
  window.location='/parents';
}
else if(selected_value == 'page-all-students'){
  window.location='/all-students';
}
else if(selected_value == 'page-student-detail'){
  window.location='/student-detail';
}
else if(selected_value == 'page-admission-form'){
  window.location='/admission-form';
}
else if(selected_value == 'page-student-promotion'){
  window.location='/student-promotion';
}
else if(selected_value == 'page-all-teachers'){
  window.location='/all-teachers';
}
else if(selected_value == 'page-teacher-detail'){
  window.location='/teacher-detail';
}
else if(selected_value == 'page-add-teacher'){
  window.location='/add-teacher';
}
else if(selected_value == 'page-assign-incharge'){
  window.location='/assign-incharge';
}
else if(selected_value == 'page-all-assign-incharge'){
  window.location='/all-assign-incharge';
}
else if(selected_value == 'page-teacher-payment'){
  window.location='/teacher-payment';
}
else if(selected_value == 'page-all-parents'){
  window.location='/all-parents';
}
else if(selected_value == 'page-parents-detail'){
  window.location='/parents-detail';
}
else if(selected_value == 'page-add-parents'){
  window.location='/add-parents';
}
else if(selected_value == 'page-all-drivers'){
  window.location='/all-driver';
}
else if(selected_value == 'page-add-driver'){
  window.location='/add-driver';
}
else if(selected_value == 'page-all-books'){
  window.location='/all-books';
}
else if(selected_value == 'page-add-book'){
  window.location='/add-book';
}
else if(selected_value == 'page-assign-book'){
  window.location='/assign-book';
}
else if(selected_value == 'page-all-assign-book'){
  window.location='/all-assign-book';
}
else if(selected_value == 'page-return-assign-book'){
  window.location='/return-assign-book';
}
else if(selected_value == 'page-all-fees'){
  window.location='/all-fees';
}
else if(selected_value == 'page-add-fees-collection'){
  window.location='/add-fees-collection';
}
else if(selected_value == 'page-all-fees-collection'){
  window.location='/all-fees-collection';
}
else if(selected_value == 'page-accept-fees'){
  window.location='/accept-fees';
}

else if(selected_value == 'page-all-expense'){
  window.location='/all-expense';
}
else if(selected_value == 'page-add-expense'){
  window.location='/add-expense';
}
else if(selected_value == 'page-add-expense-type'){
  window.location='/add-expense-type';
}
else if(selected_value == 'page-all-expense-type'){
  window.location='/all-expense-type';
}

else if(selected_value == 'page-add-fees'){
  window.location='/add-fees';
}
else if(selected_value == 'page-all-classes'){
  window.location='/all-classes';
}
else if(selected_value == 'page-add-class'){
  window.location='/add-class';
}
else if(selected_value == 'page-all-assign-classes'){
  window.location='/all-assign-class';
}
else if(selected_value == 'page-assign-class'){
  window.location='/assign-class';
}
else if(selected_value == 'page-all-mediums'){
  window.location='/all-medium';
}
else if(selected_value == 'page-add-medium'){
  window.location='/add-medium';
}
else if(selected_value == 'page-add-vehicle'){
  window.location='/add-vehicle';
}
else if(selected_value == 'page-all-vehicle'){
  window.location='/all-vehicle';
}
else if(selected_value == 'page-add-transport'){
  window.location='/add-transport';
}
else if(selected_value == 'page-all-transport'){
  window.location='/all-transport';
}
else if(selected_value == 'page-all-hostels'){
  window.location='/all-hostel';
}
else if(selected_value == 'page-add-hostel'){
  window.location='/add-hostel';
}
else if(selected_value == 'page-all-rooms'){
  window.location='/all-room';
}
else if(selected_value == 'page-add-room'){
  window.location='/add-room';
}
else if(selected_value == 'page-assign-room'){
  window.location='/assign-room';
}
else if(selected_value == 'page-all-assign-room'){
  window.location='/all-assign-room';
}
else if(selected_value == 'page-all-professions'){
  window.location='/all-profession';
}
else if(selected_value == 'page-add-profession'){
  window.location='/add-profession';
}
else if(selected_value == 'page-all-attendence'){
  window.location='/all-attendence';
}
else if(selected_value == 'page-add-attendence'){
  window.location='/add-attendence';
}
else if(selected_value == 'page-exam-schedule'){
  window.location='/exam-schedule';
}
else if(selected_value == 'page-exam-grade'){
  window.location='/exam-grade';
}
else if(selected_value == 'page-exam-result'){
  window.location='/exam-result';
}
else if(selected_value == 'page-add-message'){
  window.location='/add-message';
}
else if(selected_value == 'page-all-message'){
  window.location='/all-message';
}
else if(selected_value == 'page-button'){
  window.location='/button';
}
else if(selected_value == 'page-grid'){
  window.location='/grid';
}
else if(selected_value == 'page-tab'){
  window.location='/tab';
}
else if(selected_value == 'page-Progress-Bar'){
  window.location='/Progress-Bar';
}
}

    render() {
      

      
      
        return (
          
            <>
                <Notification />
                <div className="sidebar-main sidebar-menu-one sidebar-expand-md sidebar-color">
                <div className="mobile-sidebar-header d-md-none d-lg-none">
                  <div className="header-logo">
                    <Link to="/"><img src="img/logo1.png" alt="logo" /></Link>
                  </div>
                </div>
                <div className="sidebar-menu-content">
                {this.props &&
                  this.props.detail_data/* &&
                  this.props.detail_data.specific_user_detail &&
                  this.props.detail_data.specific_user_detail.merchant_id*/ ?(
                  <ul className="nav nav-sidebar-menu sidebar-toggle-view">
                  {this.props.detail_data.specific_user_detail  ? (
                    
                      <li className="nav-item">
                        <NavLink exact activeClassName='activee' to="/" className="nav-link"><i className="flaticon-dashboard" /><span>Home</span></NavLink>

                      </li>
                  ):null}
                  {this.props.detail_data.specific_user_detail && config.permissionType != "PARTIAL_OUTLET_ACCESS" ? (
                      <li className="nav-item">
                        <NavLink to="/orders" activeClassName='activee' className="nav-link"><i className="flaticon-classmates" /><span >Orders</span></NavLink>

                      </li>
                    ):null}
                   

{this.props.detail_data.specific_user_detail && config.permissionType != "PARTIAL_OUTLET_ACCESS" ? (
  <div className="accordion md-accordion" id="accordionEx1" role="tablist" aria-multiselectable="true" >
  <div className="card-header" role="tab" id="headingTwo1">
    <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx1" href="#collapseTwo1"
      aria-expanded="false" aria-controls="collapseTwo1">
                          <li className="nav-item">
                            <Link to="/categories" className="nav-link"><i className="flaticon-couple" style={{color:"#ffa901", marginLeft:"-20px"}}/><span style={{color:"#9ea8b5", marginLeft:"16px"}}>Products & Categories</span><i className="fas fa-angle-down rotate-icon" style={{color:"#9ea8b5",marginLeft: "10px"}}></i></Link>
                          </li>
   </a>
   </div>

   <div id="collapseTwo1" className="collapse" role="tabpanel" aria-labelledby="headingTwo1"
      data-parent="#accordionEx1" style={{backgroundColor:"rgb(231 222 215)",marginRight: "1px"}}>
   
           {this.props.detail_data.specific_user_detail ? (
                            <li className="nav-item">
                              <NavLink activeClassName='activee'to="/categories" className="nav-link"><i className="flaticon-dashboard" style={{color:"#ffa901", marginRight:"10px", marginLeft:"20px"}}/><span style={{color:"#042954"}}>Categories</span></NavLink>
                              <hr style={{backgroundColor:"#222"}}/>
                            </li>
                         
                          ):null}

                          {this.props.detail_data.specific_user_detail ? (
                            <li className="nav-item">
                              <NavLink activeClassName='activee'to="/special_deals" className="nav-link"><i className="flaticon-dashboard" style={{color:"#ffa901", marginRight:"10px", marginLeft:"20px"}}/><span style={{color:"#042954"}}>Special Deals</span></NavLink>
                              <hr style={{backgroundColor:"#222"}}/>
                            </li>
                         
                          ):null}

                          {this.props.detail_data.specific_user_detail ? (
                              <li className="nav-item">
                                <NavLink activeClassName='activee' to="/addongroups" className="nav-link"><i className="flaticon-maths-class-materials-cross-of-a-pencil-and-a-ruler" style={{color:"#ffa901", marginRight:"10px",marginLeft:"20px"}} /><span style={{color:"#042954"}}>Addon Groups</span></NavLink>
<hr style={{backgroundColor:"#222"}}/>
                              </li>
                            ):null}
                              {this.props.detail_data.specific_user_detail ? (
                              <li className="nav-item">
                                <NavLink activeClassName='activee' onClick={handlePageFn} to="/product" className="nav-link"><i className="flaticon-maths-class-materials-cross-of-a-pencil-and-a-ruler" style={{color:"#ffa901", marginRight:"10px",marginLeft:"20px"}} /><span style={{color:"#042954"}}>Categories & Products</span></NavLink>
<hr style={{backgroundColor:"#222"}}/>
                              </li>
                            ):null}


 
      </div>
    </div>
  

                        ):null}

   
                    {this.props.detail_data.specific_user_detail && config.permissionType != "PARTIAL_OUTLET_ACCESS" ? (
                        <li className="nav-item">
                          <NavLink activeClassName='activee' to="/egift_card_redeem" className="nav-link"><i className="fas fa-gift" /><span>EGift Card Redeem</span></NavLink>

                        </li>
                    ):null}
                  


                      {this.props.detail_data.specific_user_detail ? (
                          <li className="nav-item">
                            <NavLink activeClassName='activee' to="/events" className="nav-link"><i className="flaticon-couple" /><span >Specials</span></NavLink>

                          </li>
                        ):null}

{this.props.detail_data.specific_user_detail && config.permissionType != "PARTIAL_OUTLET_ACCESS" ? (
                          <li className="nav-item">
                            <NavLink activeClassName='activee' to="/coupons" className="nav-link"><i className="flaticon-couple" /><span >Coupons</span></NavLink>

                          </li>
                        ):null}

{this.props.detail_data.specific_user_detail ? (
                              <li className="nav-item">
                                <NavLink activeClassName='activee' to="/broadcast" className="nav-link"><i className="flaticon-books" /><span>Broadcast Messages</span></NavLink>

                              </li>
                            ):null}

         {/* setting part */}

                                  
{this.props.detail_data.specific_user_detail ? (
  <div className="accordion md-accordion" id="accordionEx2" role="tablist" aria-multiselectable="true" >
  <div className="card-header" role="tab" id="headingThree">
    <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx2" href="#collapseThree"
      aria-expanded="false" aria-controls="collapseThree">
                          <li className="nav-item">
                            <Link to="#" className="nav-link"><i className="flaticon-couple" style={{color:"#ffa901", marginLeft:"-20px"}}/><span style={{color:"#9ea8b5", marginLeft:"16px"}}>Setting</span><i className="fas fa-angle-down rotate-icon" style={{color:"#9ea8b5",marginLeft: "118px"}}></i></Link>
                          </li>
   </a>
   </div>

   <div id="collapseThree" className="collapse" role="tabpanel" aria-labelledby="headingThree"
      data-parent="#accordionEx2" style={{backgroundColor:"rgb(231 222 215)",marginRight: "1px"}}>
     
           {this.props.detail_data.specific_user_detail ? (
                            <li className="nav-item">
                              <NavLink activeClassName='activee' to="/holidays" className="nav-link" ><i className="flaticon-couple" style={{color:"#ffa901", marginRight:"10px", marginLeft:"20px"}}/><span style={{color:"#042954"}}>Holidays</span></NavLink>
                              <hr style={{backgroundColor:"#222"}}/>
                            </li>
                         
                          ):null}
                          {this.props.detail_data.specific_user_detail ? (
                              <li className="nav-item">
                                <NavLink activeClassName='activee' to="/rest-timing" className="nav-link"><i className="flaticon-books" style={{color:"#ffa901", marginRight:"10px",marginLeft:"20px"}} /><span style={{color:"#042954"}}>Restaurant Timings</span></NavLink>
<hr style={{backgroundColor:"#222"}}/>
                              </li>
                            ):null}



{this.props.detail_data.specific_user_detail ? (
                              <li className="nav-item">
                                <NavLink activeClassName='activee' to="/banner_logo_upload" className="nav-link"><i className="flaticon-books" style={{color:"#ffa901", marginRight:"10px",marginLeft:"20px"}} /><span style={{color:"#042954"}}>Banner Logo Upload</span></NavLink>
<hr style={{backgroundColor:"#222"}}/>
                              </li>
                            ):null}





{this.props.detail_data.specific_user_detail ? (
                              <li className="nav-item">
                                <NavLink activeClassName='activee' to="/galleries" className="nav-link"><i className="flaticon-books" style={{color:"#ffa901", marginRight:"10px",marginLeft:"20px"}} /><span style={{color:"#042954"}}>Galleries</span></NavLink>
<hr style={{backgroundColor:"#222"}}/>
                              </li>
                            ):null}


{this.props.detail_data.specific_user_detail && config.permissionType != "PARTIAL_OUTLET_ACCESS" ? (
                              <li className="nav-item">
                                <NavLink activeClassName='activee' to="/order_online_link" className="nav-link"><i className="flaticon-books" style={{color:"#ffa901", marginRight:"10px",marginLeft:"20px"}} /><span style={{color:"#042954"}}>Order Online Link</span></NavLink>
<hr style={{backgroundColor:"#222"}}/>
                              </li>
                            ):null}


{this.props.detail_data.specific_user_detail ? (
                              <li className="nav-item">
                                <NavLink activeClassName='activee' to="/outlet_setting" className="nav-link"><i className="flaticon-books" style={{color:"#ffa901", marginRight:"10px",marginLeft:"20px"}} /><span style={{color:"#042954"}}>External Order Online Link</span></NavLink>
<hr style={{backgroundColor:"#222"}}/>
                              </li>
                            ):null}

 
      </div>
    </div>
  

                        ):null}

   
{this.props.detail_data.specific_user_detail && config.permissionType != "PARTIAL_OUTLET_ACCESS" ? (
  <div className="accordion md-accordion" id="accordionEx3" role="tablist" aria-multiselectable="true" >
  <div className="card-header" role="tab" id="headingThree">
    <a className="collapsed" data-toggle="collapse" data-parent="#accordionEx3" href="#collapseFour"
      aria-expanded="false" aria-controls="collapseFour">
                          <li className="nav-item">
                            <Link to="#" className="nav-link"><i className="flaticon-couple" style={{color:"#ffa901", marginLeft:"-20px"}}/><span style={{color:"#9ea8b5", marginLeft:"16px"}}>Quick Setting</span><i className="fas fa-angle-down rotate-icon" style={{color:"#9ea8b5",marginLeft: "73px"}}></i></Link>
                          </li>
   </a>
   </div>

   <div id="collapseFour" className="collapse" role="tabpanel" aria-labelledby="headingThree"
      data-parent="#accordionEx" style={{backgroundColor:"rgb(231 222 215)",marginRight: "1px"}}>
     
         

{this.props.detail_data.specific_user_detailn ? (
                              <li className="nav-item">
                                <NavLink activeClassName='activee' to="/restaurant-settings" className="nav-link"><i className="flaticon-books" style={{color:"#ffa901", marginRight:"10px",marginLeft:"20px"}} /><span style={{color:"#042954"}}>Restaurant Settings</span></NavLink>
<hr style={{backgroundColor:"#222"}}/>
                              </li>
                            ):null}



{this.props.detail_data.specific_user_detail ? (
                              <li className="nav-item">
                                <NavLink activeClassName='activee' to="/printer-settings" className="nav-link"><i className="flaticon-books" style={{color:"#ffa901", marginRight:"10px",marginLeft:"20px"}} /><span style={{color:"#042954"}}>Printer Settings</span></NavLink>
<hr style={{backgroundColor:"#222"}}/>
                              </li>
                            ):null}




{this.props.detail_data.specific_user_detail ? (
                              <li className="nav-item">
                                <NavLink activeClassName='activee' to="/delivery-settings" className="nav-link"><i className="flaticon-books" style={{color:"#ffa901", marginRight:"10px",marginLeft:"20px"}} /><span style={{color:"#042954"}}>Delivery Settings</span></NavLink>
<hr style={{backgroundColor:"#222"}}/>
                              </li>
                            ):null}

{this.props.detail_data.specific_user_detail ? (
                              <li className="nav-item">
                                <NavLink activeClassName='activee' to="/gift-certificates" className="nav-link"><i className="flaticon-books" style={{color:"#ffa901", marginRight:"10px",marginLeft:"20px"}} /><span style={{color:"#042954"}}>Gift Certificates</span></NavLink>
<hr style={{backgroundColor:"#222"}}/>
                              </li>
                            ):null}                            
 
      </div>
    </div>
  

                        ):null}



{this.props.detail_data.specific_user_detail && config.permissionType != "PARTIAL_OUTLET_ACCESS" ? (
                                    <li className="nav-item">
                                      <NavLink activeClassName='activee' to="/download-reports" className="nav-link"><i className="flaticon-maths-class-materials-cross-of-a-pencil-and-a-ruler" /><span >Download Reports</span></NavLink>

                                    </li>
                                  ):null}

{this.props.detail_data.specific_user_detail && config.permissionType != "PARTIAL_OUTLET_ACCESS" ? (
                                      <li className="nav-item">
                                        <NavLink activeClassName='activee' to="" className="nav-link"><i className="flaticon-maths-class-materials-cross-of-a-pencil-and-a-ruler" /><span >Publish Data</span></NavLink>

                                      </li>
                                    ):null}


                              {this.props.detail_data.specific_user_detail ? (
                                  <li className="nav-item">
                                    <NavLink activeClassName='activee' to="/contact-us" className="nav-link"><i className="flaticon-maths-class-materials-cross-of-a-pencil-and-a-ruler" /><span >Contact Us</span></NavLink>

                                  </li>
                                ):null}

                                {/* {this.props.detail_data.specific_user_detail ? (
                                    <li className="nav-item">
                                      <NavLink activeClassName='activee' to="/marketing-plan" className="nav-link"><i className="flaticon-maths-class-materials-cross-of-a-pencil-and-a-ruler" /><span >Marketing & Branding</span></NavLink>

                                    </li>
                                  ):null} */}










                     
                  </ul>
                ):(<ul className="nav nav-sidebar-menu sidebar-toggle-view loadingstate">
                    <li className="nav-item">
                      <Link to="/" activeClassName='active' className="nav-link"><i className="flaticon-dashboard" /><span>Home</span></Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" className="nav-link"><i className="flaticon-dashboard" /><span>Orders</span></Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" className="nav-link"><i className="flaticon-dashboard" /><span>Products & Categories</span></Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" className="nav-link"><i className="flaticon-dashboard" /><span>EGift Card Redeem</span></Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" className="nav-link"><i className="flaticon-dashboard" /><span>Specials</span></Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" className="nav-link"><i className="flaticon-dashboard" /><span>Broadcast Messages</span></Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/" className="nav-link"><i className="flaticon-dashboard" /><span>Setting</span></Link>
                    </li>
                    <li className="nav-item">
                       <Link to="/" className="nav-link"><i className="flaticon-dashboard" /><span>Download Reports</span></Link>
                    </li>
                    <li className="nav-item">
                       <Link to="/" className="nav-link"><i className="flaticon-dashboard" /><span>Contact Us</span></Link>
                    
                    </li>
                   {/* <li className="nav-item">
                       <Link to="/" className="nav-link"><i className="flaticon-dashboard" /><span>Marketing & Branding</span></Link>
                    </li> */}
                    </ul>

                )
              }
                </div>
              </div>

          </>
        );
    }
}

const mapStateToProps = state => ({
  detail_data: state.SpecificUserDetail
});

const handlePageFn = () => {
  localStorage.removeItem('product_page');
}

export default connect(mapStateToProps, null)(Sidebar);;
