import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOutletStatus, enableOutlet, disableOutlet } from "../Redux/Outlet/OutletActions";
import { uploadImage } from "../Redux/Gallery/GalleryActions";
import Switch from "react-switch";
import Loader from 'react-loader-spinner';
import { Link, useHistory } from "react-router-dom";
// import "./App.css"; // import your CSS

function Outlet() {
    const outletData = useSelector(state => state.Outlet);
    // store data access End
    const dispatch = useDispatch();

    const history = useHistory();
  const goToBack = () => {
      history.goBack();
  }
    
    const [errorMessage, setErrorMessage] = useState('');
    const [field, setField] = useState('');
    const [isAdded, setAdded] = useState(false);
    const [isDeleted, setDeleted] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [outletStatus, setOutletStatus] = useState(false);
    const [isLoaded, setLoaded] = useState(null);

    useEffect(() => {
      setLoaded(false);
      dispatch(getOutletStatus());
    },[])

    
    useMemo(() => {
      if(outletData.get_error) {
        setLoaded(true);
        setErrorMessage(outletData.get_error.object.error)
      }

      if(outletData.outlet_status && outletData.outlet_status.object && !isAdded && !isDeleted) {
        setLoaded(true);
        setErrorMessage(null);
        setField(outletData.outlet_status.object.orderonline_link)
        setOutletStatus(outletData.outlet_status.object.is_outlet_store)
      }
      
      if(outletData.outlet && outletData.outlet.request_status && isAdded) {
        setLoaded(true);
        setErrorMessage(null);
        setResponseMessage("outlet Enabled!");
      }

      if(outletData.delete_outlet && outletData.delete_outlet.request_status && isDeleted) {
        setLoaded(true);
        setErrorMessage(null);
        setResponseMessage("outlet Deleted!");
      }

    }, [outletData])
  //external order online link
    
    const saveFormData = () => {
      setLoaded(false);
      setAdded(true);
      setDeleted(false);
      if(field.trim() == ''){
        setErrorMessage('Field required!');
      } else {
        dispatch(enableOutlet(field));
      }
    }

    

    const enableDisableOutlet = (outletStatus) => {
      setOutletStatus(outletStatus)
      if(!outletStatus) {
        setLoaded(false);
        setAdded(false);
        setDeleted(true);
        dispatch(disableOutlet(field));
      }
    }

    
    return (<>
    <div className="breadcrumbs-area">
        <button className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark right-float" onClick={(e)=>goToBack()}>Back</button>
        <h3>External Order Online Link</h3>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li onClick={(e)=>goToBack()}>
          External Order Online Link
          </li>
        </ul>
      </div>
     <div className="card">
      <div className="card-body">
      {isLoaded == false && <div style={{textAlign: 'center'}}>
                  <Loader
                    className="student-detail-loader"
                    type="ThreeDots"
                    color="#fea801"
                    height={90}
                    width={150}
                  />
                </div> }
        {responseMessage ?
          <p className="alert alert-success w-auto" role="alert">
            {responseMessage}
          </p> : <p></p>
        }

        {errorMessage ?
          <p className="alert alert-danger w-auto" role="alert">
            {errorMessage}
          </p> : <p></p>
        }       
       
       <div className="row">
             <div className="col-lg-6 col-12 form-group">
                <label style={{marginLeft:'30px'}}>Enable External Link</label>
                <div style={{marginTop:'-30px', marginLeft:'180px'}}><Switch
                        onChange={(e) =>
                          enableDisableOutlet(!outletStatus)
                        }
                        checked={outletStatus}
                        uncheckedIcon={false}
                        checkedIcon={true}                        
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"/>
                        </div>
                </div>

                <div className="col-lg-6 col-12 form-group">
                    <label>URL</label>
                   <input type="text" placeholder className="form-control" value={field} onChange={(e) =>setField(e.target.value)} required />
                </div>
        </div>
  
          
        </div>
        {/* <pre className="output">{JSON.stringify(forms, null, 2)}</pre> */}
        <div className="col-12 form-group mg-t-8">
           <button type="button" className="right-float btn-fill-lg btn-gradient-yellow btn-hover-bluedark" onClick={saveFormData}>Save</button>
        </div>
     
        </div></>
    );
}
  
export default Outlet;